<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <h2 class="principal header-title text-center">Bem-vindo ao eRoyal!</h2>
      </div>
    </div>
    <div class="row justify-content-md-center">
      <div class="col-md-10">
        <div class="row justify-content-md-center wrap-dashboard">
          <!-- Cotações -->
          <div v-if="isSuperAdmin || isPortalTester || isUserAllowOnlineProposal" class="d-flex col-12 col-sm-12 col-md-6 col-lg-3">
            <div class="card principal">
              <router-link :to="{name: 'ProposalIndex'}">
                <div class="card-body">
                  <div class=" justify-content-between mb-4">
                    <div>
                      <h5 class="box-title mb-2">{{ $t('sidebar.proposal') }}</h5>
                    </div>
                  </div>

                  <div class=" d-flex justify-content-md-center mb-4">
                    <div>
                      <i class="principal-item-icon ft-clock"></i>
                    </div>
                  </div>

                  <div class="content justify-content-between mb-4">
                    <div>
                      <div class="text-muted font-13">{{ $t('kpi.proposal.description') }}</div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
         <!-- &lt;!&ndash; Booking Control &ndash;&gt; -->
         <!-- <div v-if="isSuperAdmin || isBookingAdmin || isBookingRequester" class="d-flex col-12 col-sm-12 col-md-6 col-lg-3">
           <div class="card principal">
             <router-link :to="{name: 'BookingIndex'}">
               <div class="card-body">
                 <div class=" justify-content-between mb-4">
                   <div>
                     <h5 class="box-title mb-2">{{ $t('sidebar.bookings') }}</h5>
                   </div>
                 </div>

                 <div class=" d-flex justify-content-md-center mb-4">
                   <div>
                     <i class="principal-item-icon ft-anchor"></i>
                   </div>
                 </div>

                 <div class="content justify-content-between mb-4">
                   <div>
                     <div class="text-muted font-13">{{ $t('booking.description') }}</div>
                   </div>
                 </div>
               </div>
             </router-link>
           </div>
         </div> -->
          <!-- Processes -->
          <div class="d-flex col-12 col-sm-12 col-md-6 col-lg-3">
            <div class="card principal">
              <router-link :to="{name: 'ProcessList'}">
                <div class="card-body">
                  <div class=" justify-content-between mb-4">
                    <div>
                      <h5 class="box-title mb-2">{{ $t('kpi.process.title') }}</h5>
                    </div>
                  </div>

                  <div class=" d-flex justify-content-md-center mb-4">
                    <div>
                      <i class="principal-item-icon ft-archive"></i>
                    </div>
                  </div>

                  <div class="content justify-content-between mb-4">
                    <div>
                      <div class="text-muted font-13">{{ $t('kpi.process.description') }}</div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <!-- KPI -->
          <div class="d-flex col-12 col-sm-12 col-md-6 col-lg-3">
            <div class="card principal">
              <router-link :to="{name: 'KpiProcesses'}">
                <div class="card-body">
                  <div class=" justify-content-between mb-4">
                    <div>
                      <h5 class="box-title mb-2">{{ $t('kpi.title') }}</h5>
                    </div>
                  </div>

                  <div class=" d-flex justify-content-md-center mb-4">
                    <div>
                      <i class="principal-item-icon ft-bar-chart-line"></i>
                    </div>
                  </div>

                  <div class="content justify-content-between mb-4">
                    <div>
                      <div class="text-muted font-13">{{ $t('kpi.description') }}</div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <!-- Financeiro -->
          <div class="d-flex col-12 col-sm-12 col-md-6 col-lg-3">
            <div class="card principal">
              <router-link :to="{name: 'FinantialInvoices'}">
                <div class="card-body">
                  <div class=" justify-content-between mb-4">
                    <div>
                      <h5 class="box-title mb-2">{{ $t('kpi.finantial_position.title') }}</h5>
                    </div>
                  </div>

                  <div class=" d-flex justify-content-md-center mb-4">
                    <div>
                      <i class="principal-item-icon ft-dollar-sign"></i>
                    </div>
                  </div>

                  <div class="content justify-content-between mb-4">
                    <div>
                      <div class="text-muted font-13">{{ $t('kpi.finantial_position.description') }}</div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <!-- Contato Diretores -->
          <div class="d-flex col-12 col-sm-12 col-md-6 col-lg-3">
            <div class="card principal">
              <router-link :to="{name: 'ContactDirectors'}">
                <div class="card-body col-sm-12">
                  <div class=" justify-content-between mb-4">
                    <div>
                      <h5 class="box-title mb-2">{{ $t('contact.directors.title') }}</h5>
                    </div>
                  </div>

                  <div class=" d-flex justify-content-md-center mb-4">
                    <div>
                      <i class="principal-item-icon ti-id-badge"></i>
                    </div>
                  </div>

                  <div class="content justify-content-between mb-4">
                    <div>
                      <div class="text-muted font-13">{{ $t('contact.directors.shortDescription') }}</div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>

        </div>
      </div>
    </div>
    <CookieWarning v-if='hasCookie()' />
  </div>
</template>

<script>
import CookieWarning from '@/components/CookieWarning.vue'

export default {
  name: 'Dashboard',
  components: {
    CookieWarning
  },
  computed: {
    isUserAllowOnlineProposal () {
      let userInfo = this.$store.getters.userInfo || null

      if (!userInfo) {
        return false
      } else if (userInfo.roles.indexOf('ROLE_EMPLOYEE') !== -1) {
        return true
      } else if (userInfo.customers_allow_online_proposal_list.length > 0) {
        return true
      }

      return false
    },
    isPortalTester () {
      let status = false
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_PORTAL_TESTER') !== -1) {
        status = true
      }
      return status
    },
    isSuperAdmin () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_SUPER_ADMIN') !== -1) {
        return true
      }
      return false
    },
    isBookingAdmin () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_BOOKING_MANAGER') !== -1) {
        return true
      }
      return false
    },
    isBookingRequester () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_BOOKING_REQUESTER') !== -1) {
        return true
      }
      return false
    }
  },
  metaInfo: {
    titleTemplate: 'Login - %s'
  },
  data () {
    return {
    }
  },
  methods: {
    hasCookie () {
      let cookies = global.instanceApp.$Cookie.get()
      if (cookies['_consent_']) {
        if (global.instanceApp.$Cookie.get('_consent_') === 1) {
          localStorage.removeItem('_userStoredFilters_')
          global.instanceApp.$Cookie.remove('_userStoredFilters_')
        }
        return false
      } else {
        return true
      }
    }
  }
}
</script>
